/* 

@font-face {
  font-family: Regular;
  src: url("https://lsqappcommoncontent.s3-ap-southeast-1.amazonaws.com/leadsquared/common/fonts/SegoeUI/segoeui.ttf") format("truetype");
}

@font-face {
  font-family: Bold;
  src: url("https://lsqappcommoncontent.s3-ap-southeast-1.amazonaws.com/leadsquared/common/fonts/SegoeUI/segoeuib.ttf") format("truetype");
}

@font-face { font-family: Light; src: url("https://lsqappcommoncontent.s3-ap-southeast-1.amazonaws.com/leadsquared/common/fonts/SegoeUI/segoeuil.ttf") format("truetype"); }

@font-face {
  font-family: SemiBold;
  src: url("https://lsqappcommoncontent.s3-ap-southeast-1.amazonaws.com/leadsquared/common/fonts/SegoeUI/seguisb.ttf") format("truetype");
}

body {
  margin: 0;
  font-family:"Regular", "sans-serif"
}

:root{
  --base-font-color: #454F5B
}
 */


#root {
  height: 100vh;
}

/* Header*/
 #mx-site-header {
    
  display: block;
  clear: both;
  position: static;
}

.navbar-fixed-top {
top: 0;
right: 0;
left: 0;
z-index: 1030;
}

.navbar {
color: #999999;
}

#mx-site-header .navbar .navbar-inner {
box-shadow: none;
background-image: url(https://f1.leadsquaredcdn.com/leadsquared/app/LeadSquared/Common/img/header_bg.png);
background-repeat: repeat-x;
filter: none;
}

::-webkit-scrollbar {
 background-color:transparent;
}

.navbar-fixed-top .navbar-inner {
padding-left: 0;
padding-right: 0;
-webkit-border-radius: 0;
-moz-border-radius: 0;
border-radius: 0;
}

.grey1-bg {
background-color: #efefef !important;
}

.container:before, .container:after {
display: table;
content: "";
}

.navbar .brand {
padding: 0px;
float: left;
display: block;
padding: 8px 20px 12px;
margin-left: -20px;
font-size: 20px;
font-weight: 200;
line-height: 1;
color: #999999;
margin-left: 0px;
}

#mx-site-header .mx-logo-bg {
position: relative;
display: block;
min-width: 28px;
height: 41px;
padding: 4px 10px 4px 0px;
}
#mx-site-header .brand span.lsq-logo-icon {
max-height: 28px;
width: 28px;
padding-top: 5px;
display: block;
float: left;
height: 26px;
background: url(https://f1.leadsquaredcdn.com/cdn/img/leadsquared/master-sprite-v5.png);
background-position: 0px 6px;
background-repeat: no-repeat;
}
#mx-site-header .brand span.lsq-logo-text-icon {
max-height: 30px;
width: 145px;
padding-top: 5px;
display: block;
float: left;
height: 30px;
background: url(https://f1.leadsquaredcdn.com/cdn/img/leadsquared/master-sprite-v5.png);
background-position: -28px 6px;
background-repeat: no-repeat;
}
.container:after {
clear: both;
}
.container:before, .container:after {
display: table;
content: "";
}

.container, .navbar-fixed-top .container, .navbar-fixed-bottom .container {
width: auto;
margin: auto 4% !important;
}

/* Report Listing */
.sv-reports-page-wrapper {
  padding: 40px 20px;
  font-size: 16px;
  color: var(--base-font-color);
}
.sv-reports-container{
  padding: 0 18px;
}
.sv-report-items{
  margin: 24px 0 0 0;
}
.sv-report-items-heading{
  font-size: 32px;
  font-family: "SemiBold";
  height: 48px;
}

.sv-report-items-header-wrapper {
  display: flex;
  height: 32px;
  align-items: center;
  border-bottom: 1px solid #DFE3E8;
  font-family: "SemiBold";
  margin: 5px 0;
}
.sv-report-item-wrapper{
  display: flex;
  align-items: center;
  padding: 5px;
  height: 48px;
  cursor: pointer;
  text-decoration: none;
  color: var(--base-font-color);
}

.sv-report-item-wrapper:hover{
  background-color: rgba(80, 158, 227, 0.08);
  transition: 300ms;
}

.sv-report-item-type {
  width: 80px;
}
.sv-report-item-type-button{
  position: relative;
  color: #309AFC;
  height: 32px;
  width: 32px;
  background-color: rgb(221, 236, 250);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sv-report-item-name {
  min-width: 400px;
}

.sv-report-item-desc {
  width: 100%;
}
.sv-report-item-icon, .sv-hover-icon{
  padding: 4px 0 0 0;
}

.sv-report-item-icon > svg {
  height: 24px;
}
.sv-hover-icon > svg {
  height: 22px;
}

.sv-report-item-wrapper:hover .sv-hover-icon{
  transform: scale(1);
  transition: 300ms;
}

.sv-report-item-wrapper:hover .sv-report-item-icon{
  transform: scale(0);
}
.sv-hover-icon{
  transform: scale(0);
  position: absolute;
  top: 0;
  padding: 5px 0 0 0;
}

.sv-rotate-icon{ transform: rotate(90deg); height: 100%; width: 100%; display: flex; align-items: center; justify-content: center }

/* React-select */

.rs-selectall-btn {
  padding: 0;
  border: 0;
  background: transparent;
  display: flex;
  align-items: center;
  margin-right: 7px;
}

.rs-option-checkbox-cn {
  display: flex;
  align-items: center;
  margin-right: 8px;
}

.rs-option-label-cn, .re-multivalue-cn {
  flex-grow: 1;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}


.report .se-sidenav-container .se-side-navbar-hamburger{
  top:17px;
}

.report-list .se-sidenav-container .se-side-navbar-hamburger{
  top:25px;
}
.report-list.expand .se-report-header-label {
margin-left: 0;
}

.report.collapse .se-header-container{
  margin-left: 0;
  }

.se-report-card-tooltip{
    max-width: 293px;
    box-shadow: 0px 3px 4px #00000029;
    background: #F4F6F8 ;
}
.se-row-group-sort{
  max-width: 200px;
}

.se-non-row-sort .rc-tooltip-content {
 display: none ;
}

.ag-tooltip{
  font-family: "Regular","sans-serif";
  min-height: 22px !important;
  padding: 0 10px !important;
  color: #454F5B !important;
  text-align: left;
  -webkit-text-decoration: none;
  text-decoration: none;
  background-color: #F4F6F8 !important;
  border-radius: 3px;
  border: 1px solid #F4F6F8 !important;
  box-shadow: 0px 3px 4px #00000029;
}